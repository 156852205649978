import "./Description.css";
export default function Description() {
	return (
		<div className="section-container">
			<img className="dark-text-logo" alt="" />
			<h2 className="desc-header">اولترانت ! سرعه, جوده وثبات</h2>
			<p className="desc-text">
				شبكة الترانت تجلب إلى بيتكم شبكة خطوط فائقة السرعة الأكثر تطوّرًا في العالم مقارنة مع تكنولوجيا
				الاتصالات التقليدية.خطوط فائقة السرعة هي تكنولوجية المستقبل في مجال البنى التحتية للاتصالات حيث أنها
				أكثر قدرة على نقل البيانات بجودة عالية كما تمكّن المشترك من الحصول على سرعات تحميل ورفع عالية جداً،
				وهي التي تشكل انطلاقة في عالم سرعات التصفّح في العالم. وتتيح عالمًا جديدًا من الخدمات غير المسبوقة في
				مجالات كثيرة والتطبيقات الحديثة التي تشمل مكتبات التلفاز التفاعلي والعاب الفيديو الإلكترونية
			</p>
		</div>
	);
}
