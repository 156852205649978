// Font Awesome Icons Import
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "./Form.css";
// Font Awesome Component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Form() {
  return (
    <main className="section-container" id="form">
      <section className="form-container">
        {/* Text Images Container */}
        <div className="img-container">
          <img className="top-motto" alt="" />
          <img
            className="middle-motto"
            src="../../assets/imgs/text-img2.png"
            alt=""
          ></img>
          <img
            className="bottom-motto"
            src="../../assets/imgs/text-img3.png"
            alt=""
          ></img>
          <img
            className="text-logo"
            src="../../assets/imgs/light-text-logo.png"
            alt=""
          ></img>
        </div>
      </section>
      <div className="contact-info">
        <a className="whatsapp-info" href="https://wa.me/97246670066">
          <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
          <p className="whatsapp-text">Whatsapp</p>
          <p className="whatsapp-phone-number">04 - 6670066</p>
        </a>
        <a className="phone-info" href="tel:046670066">
          <FontAwesomeIcon icon={faPhone} className="phone-icon" />
          <p className="phone-number">04 - 6670066</p>
        </a>
        <a className="email-info" href="mailto:operator1@ultranet.co.il">
          <FontAwesomeIcon icon={faEnvelope} className="email-icon" />
          <p className="email-text">operator1@ultranet.co.il</p>
        </a>
      </div>
    </main>
  );
}
