// Font Awesome Icon Import
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import "./Navbar.css";
// Font Awesome Component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Navbar() {
  return (
    <nav>
      <div className="btn-container">
        <a href="https://wa.me/97246670066">
          <FontAwesomeIcon icon={faWhatsapp} className="nav-whatsapp-icon" />
        </a>
        <a href="tel:046670066">
          <FontAwesomeIcon icon={faPhone} className="nav-phone-icon" />
        </a>
      </div>
    </nav>
  );
}
