// Img Imports
import Connectivity from "../../assets/imgs/connectivity.png";
import Rural from "../../assets/imgs/rural.png";
import Speed from "../../assets/imgs/speed.png";

import Card from "../../components/Card/Card";
export default function Benefits() {
	return (
		<div className="section-container">
			<h2 className="section-header">بما نختلف !</h2>
			<div className="cards-container">
				<Card
					imgSrc={Connectivity}
					text="ربط مناطق بشبكه فائقة السرعة شركات اخرى مثل بيزك وهوت غير معنية بربطها"
				/>
				<Card imgSrc={Rural} text="الوصول إلى المناطق الريفية والمنعزلة" />
				<Card imgSrc={Speed} text="سرعات تحميل ورفع عالية تصل حتى سرعه 1000 ميجا في رزم اشتراك مختلفة" />
			</div>
		</div>
	);
}
