import "./Card.css";
export default function Card({ imgSrc, text }: PropTypes) {
	return (
		<div className="card">
			<img src={imgSrc} alt="" className="card-img" />
			<p className="card-text">{text}</p>
		</div>
	);
}
// This interface is used to define the expected types of input props into the component
interface PropTypes {
	imgSrc: string;
	text: string;
}
