import Form from "./sections/Form/Form";
import Navbar from "./components/Navbar/Navbar";
import Description from "./sections/Description/Description";
import Benefits from "./sections/Benefits/Benefits";
import Network from "./sections/Network/Network";

import "./App.css";
function App() {
	return (
		<div className="main-container">
			<Form />
			<Description />
			<Benefits />
			<Network />
			<Navbar />
		</div>
	);
}

export default App;
