// Img Imports
import Devices from "../../assets/imgs/devices.png";
import Video from "../../assets/imgs/videos-4k.png";
import Zoom from "../../assets/imgs/zoom-video.png";
import Live from "../../assets/imgs/live-stream.png";
import Games from "../../assets/imgs/games.png";
import Cloud from "../../assets/imgs/cloud.png";

import "./Network.css";

import Card from "../../components/Card/Card";

export default function Network() {
	return (
		<div className="section-container network-container">
			<h2 className="section-header">مميزات الشبكة</h2>
			<div className="cards-container">
				<Card imgSrc={Devices} text="تصفح عبر عدد من الأجهزة في نفس الوقت بدون تشويش" />
				<Card imgSrc={Video} text="4K مشاهدة مضامين وأفلام بجودة" />
				<Card imgSrc={Zoom} text="مكالمات ڨيديو وزوم بدون تقطع" />
			</div>
			<div className="cards-container">
				<Card imgSrc={Live} text="مشاهده بث مباش بدون تقطع" />
				<Card imgSrc={Games} text="ألعاب أون لاين بدون تقطع" />
				<Card imgSrc={Cloud} text="تخزين وتحميل صور وملفات بسرعة" />
			</div>
		</div>
	);
}
